<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Eğitmen Ekle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" md="6" name="name">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="İsim"
                  v-model="form.name"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="surname">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Soyisim"
                  v-model="form.surname"
                  @blur="$v.form.surname.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="title">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Unvan"
                  v-model="form.title"
                  @blur="$v.form.title.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="email">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Eposta"
                  v-model="form.email"
                  @blur="$v.form.email.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="phone">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Cep Telefonu"
                  v-model="form.phone"
                  @blur="$v.form.phone.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="password">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Şifre"
                  v-model="form.password"
                  @blur="$v.form.password.$touch()"
                ></v-text-field>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  props: ['visible', 'schoolId'],

  mixins: [validationMixin],

  validations: {
    form: {
      name: { required },
      surname: { required },
      title: {},
      email: { required, email },
      phone: {},
      password: { required }
    }
  },

  data() {
    return {
      loading: true,
      form: {
        schoolId: this.schoolId
      }
    };
  },
  methods: {
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          await this.axios.post('admin/instructors', this.form);
          this.close();
          this.$emit('done');
          this.$store.dispatch('setToast', {
            text: 'Başarılı bir şekilde eklendi!',
            color: 'success'
          });
        }
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Ekleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
      this.$v.$reset();
      this.form = {
        schoolId: this.schoolId
      };
    }
  }
};
</script>
