<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="gutter-b">
    <card title="Eğitmen">
      <v-menu slot="toolbar" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" outlined dark v-on="on">
            İşlemler
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="instructorAddModal = true">
            Eğitmen Ekle
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="row">
        <div class="col-md-12">
          <v-text-field
            :value="search"
            @change="v => (search = v)"
            append-icon="mdi-magnify"
            label="Ara"
            single-line
            style="padding: 0; margin: 0"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="instructors"
        :loading="loading"
        :server-items-length="total"
        :options.sync="options"
        :search="search"
        :single-expand="true"
        show-expand
        multi-sort
      >
        <template v-slot:item.status="{ item }">
          {{ item.status ? 'Aktif' : 'Pasif' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="approve(item.id)" v-if="!item.status" class="mr-2">
            mdi-check-bold
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="$refs.instructorEditModal.open(item.id)"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            @click="
              deletePromt = true;
              deleteId = item.id;
            "
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <b-table-simple>
              <b-tr>
                <b-th>Kurum</b-th>
                <b-th>İl</b-th>
                <b-th>İlçe</b-th>
                <b-th>Semt</b-th>
                <b-th>Mahalle</b-th>
              </b-tr>
              <b-tr v-for="school in item.schools" :key="school.id">
                <b-td>
                  <router-link
                    :to="{ name: 'school', params: { schoolId: school.id } }"
                  >
                    {{ school.name }}
                  </router-link>
                </b-td>
                <b-td>{{ school.iller.il_adi }}</b-td>
                <b-td>{{ school.ilceler.ilce_adi }}</b-td>
                <b-td>{{ school.semtler.semt_adi }}</b-td>
                <b-td>{{ school.mahalleler.mahalle_adi }}</b-td>
              </b-tr>
            </b-table-simple>
          </td>
        </template>
      </v-data-table>
    </card>
    <DeletePromt
      :visible="deletePromt"
      @close="deletePromt = false"
      @done="deleteInstructor()"
    >
    </DeletePromt>
    <InstructorAdd
      :visible="instructorAddModal"
      @close="instructorAddModal = false"
      @done="getInstructors()"
    ></InstructorAdd>
    <InstructorEdit
      ref="instructorEditModal"
      @done="getInstructors()"
    ></InstructorEdit>
  </div>
</template>

<script>
import DeletePromt from '@/view/content/components/DeletePromt';
import InstructorAdd from './InstructorAdd';
import InstructorEdit from './InstructorEdit';

export default {
  components: { InstructorAdd, InstructorEdit, DeletePromt },
  data() {
    return {
      headers: [
        { text: 'Ad Soyad', value: 'fullName' },
        { text: 'Görev', value: 'title' },
        { text: 'Eposta', value: 'email' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Durumu', value: 'status' },
        { text: 'İşlemler', value: 'actions', sortable: false }
      ],
      instructors: [],
      options: {
        itemsPerPage: 10
      },
      instructorAddModal: false,
      deletePromt: false,
      deleteId: null,
      treeSearch: '',
      search: '',
      loading: true,
      total: 0
    };
  },
  mounted() {
    this.getInstructors();
  },
  methods: {
    async getInstructors() {
      try {
        const {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          search,
          filter
        } = this.requestQuery;

        const sort = {};
        sortBy.map((item, index) => {
          const array = item.split('.');
          if (array.length === 1) {
            sort[item] = sortDesc[index] ? 'desc' : 'asc';
          } else {
            sort[array[0]] = {};
            sort[array[0]][array[1]] = sortDesc[index] ? 'desc' : 'asc';
          }
        });

        const { data } = await this.axios.get('admin/instructors', {
          params: {
            page: {
              size: itemsPerPage,
              number: page
            },
            sort,
            search: {
              fullName: search
            },
            filter
          }
        });
        this.instructors = data.rows;
        this.total = data.count;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteInstructor() {
      try {
        await this.axios.delete('admin/instructors/' + this.deleteId);

        this.getInstructors();
        this.deletePromt = false;
        this.deleteId = null;
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Silme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    async approve(id) {
      try {
        const result = await confirm('Onaylamak istediğinize emin misiniz?');
        if (!result) return false;

        await this.axios.patch('admin/instructors/' + id + '/approve');

        this.getInstructors();
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde onaylandı!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Onaylama esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    }
  },
  computed: {
    requestQuery() {
      return {
        ...this.options,
        search: this.search
      };
    }
  },
  watch: {
    requestQuery: {
      handler: 'getInstructors',
      deep: true
    }
  }
};
</script>
