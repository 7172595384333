<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xxl-12">
          <InstructorList />
        </div>
      </div>
      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import InstructorList from '@/view/content/components/Instructor/InstructorList';

export default {
  name: 'dashboard',
  components: {
    InstructorList
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Kontrol Paneli' }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('.nav-link');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active');
      }

      // set current active tab
      event.target.classList.add('active');

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute('data-tab'));
    }
  }
};
</script>
<style lang="scss">
@import '../../../../node_modules/vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

.v-data-table th {
  font-size: 15px !important;
}
</style>
